
function getProgressBarWidth(value) {
      const score = parseFloat(value);
      const width = (score * 20) + "%";
      return width;
    };

/**
 * Displays an alert with the specified title, message, status, and optional redirect.
 * @param {string} title - The title of the alert.
 * @param {string} msg - The message to be displayed in the alert.
 * @param {number} status - The status code of the alert. Can be 200, 400, 500, or null.
 * @param {string|null} redirect - The optional redirect URL after the alert is closed.
 */
function ShowAlert(title, msg, status, redirect){
    const t = `<h3 class="card-title">${(title)}</h3>`;
    const html = `<p class="card-text">${msg}</p>`;
  if (status == 400 || status == 500 || status == null) {
      this.$swal({
        title: t,
        html: html,
        icon: 'error'
      }).then(() => {
        if (redirect != null) {
          this.$router.push(redirect);
        }
      });
    } 
    if (status == 200){
      this.$swal.fire({
         title: t,
         html: html,
			   icon: 'success',
		   }).then(() =>{
        if (redirect != null){
			   this.$router.push(redirect)
        }
		   })
    }
  }

module.exports = {getProgressBarWidth: getProgressBarWidth, ShowAlert: ShowAlert};
