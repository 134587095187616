<template>
  <Headers />
  <section class="hero-home">
    <div class="swiper-container hero-slider">
      <div class="swiper-wrapper dark-overlay">
        <div style="background-image:url(/frontend/img/photo/photo-1501621965065-c6e1cf6b53e2.jpg)"
          class="swiper-slide"></div>
        <div style="background-image:url(/frontend/img/photo/photo-1495562569060-2eec283d3391.jpg)"
          class="swiper-slide"></div>
        <div style="background-image:url(/frontend/img/photo/photo-1490578474895-699cd4e2cf59.jpg)"
          class="swiper-slide"></div>
        <div style="background-image:url(/frontend/img/photo/photo-1534850336045-c6c6d287f89e.jpg)"
          class="swiper-slide"></div>
        <div style="background-image:url(/frontend/img/photo/photo-1526392060635-9d6019884377.jpg)"
          class="swiper-slide"></div>
      </div>
    </div>
    <div class="container py-6 py-md-7 text-white z-index-20">
      <div class="row">
        <div class="col-xl-10">
          <div class="text-center text-lg-left">
            <h5 class="subtitle letter-spacing-3 mb-2 text-secondary text-shadow">Tu viaje Cómodo y seguro</h5>
            <h1 class="display-3 font-weight-bold text-shadow">TRAVI</h1>
          </div>
          <div class="search-bar mt-5 p-3 p-lg-1 pl-lg-4">
            <div class="row">
              <div class="col-lg-4 d-flex align-items-center form-group">
                <!-- <input placeholder="¿Cuando quieres viajar?" class="textbox-n form-control border-0 shadow-0"
                  v-model="date" type="text" onfocus="(this.type='date')" id="datepicker" onblur="(this.type='text')" /> -->
                <input placeholder="¿Cuando quieres viajar?" class="textbox-n form-control border-0 shadow-0"
                  v-model="date" :type="inputType" @focus="handleFocus" @blur="handleBlur" id="datepicker" />
              </div>
              <div class="col-lg-3 d-flex align-items-center form-group ">
                <select v-model="origin" title="Origen" data-style="btn-form-control" class="select-form noborder">
                  <option selected disabled value="">Origen</option>
                  <option v-for="city in cities" :value="city">{{ city }}</option>
                </select>
              </div>
              <div class="col-lg-3 d-flex align-items-center form-group ">
                <select v-model="destination" title="Destino" data-style="btn-form-control"
                  class="select-form noborder">
                  <option disabled value="" selected>Destino</option>
                  <option v-for="city in cities" :value="city">{{ city }}</option>
                </select>
              </div>
              <div class="col-lg-2" v-if="PersonType === 'Driver'">
                <button class="btn btn-primary " style="width:90%"
                  @click="$router.push('driverpreview')">Buscar</button>
              </div>
              <div class="col-lg-2 " v-else>
                <button class="btn btn-primary " style="width:90%" @click="search">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br>
  <div class="container position-relative justify-content-center mt-n6 z-index-20 ">
    <ul role="tablist" class="nav nav-tabs search-bar-nav-tabs">
      <li class="nav-item mr-2"><a href="#usuario" data-toggle="tab" role="tab" class="nav-link active">Pasajero</a>
      </li>
      <li class="nav-item mr-2"><a href="#conductor" data-toggle="tab" role="tab" class="nav-link">Conductor</a></li>
    </ul>
    <div class="search-bar search-bar-with-tabs p-3 p-lg-4">
      <div class="tab-content">
        <div id="usuario" role="tabpanel" class="tab-pane fade show active">
          <div class="container">
            <div class="text-center pb-lg-4">
              <p class="subtitle text-secondary">Puerta a puerta entre ciudades </p>
              <h2 class="mb-5">Tu viaje ideal</h2>
            </div>
            <div class="row">
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <svg class="svg-icon text-primary w-2rem h-2rem">
                      <use xlink:href="#destination-map-1"></use>
                    </svg>
                  </div>
                  <h3 class="h5">Desde y a donde quieras</h3>
                  <p class="text-muted">
                    Explora nuestras opciones de viaje y elige la mejor ruta para ti.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <svg class="svg-icon text-primary w-2rem h-2rem">
                      <use xlink:href="#pay-by-card-1"></use>
                    </svg>
                  </div>
                  <h3 class="h5">Seguro de principio a fin</h3>
                  <p class="text-muted">
                    Viaja con la tranquilidad de saber que estás cubierto en todo momento.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <svg class="svg-icon text-primary w-2rem h-2rem">
                      <use xlink:href="#heart-1"></use>
                    </svg>
                  </div>
                  <h3 class="h5">Compartir tiene recompensa</h3>
                  <p class="text-muted">
                    Comparte tus viajes y gana puntos para obtener descuentos en tus próximos viajes.
                  </p>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary" @click="$router.push('userpreview')">Saber más</button>
            </div>
          </div>

        </div>
        <div id="conductor" role="tabpanel" class="tab-pane fade">
          <div class="container">
            <div class="text-center pb-lg-4">
              <p class="subtitle text-secondary">Puerta a puerta entre ciudades </p>
              <h2 class="mb-5">Tu viaje ideal</h2>
            </div>
            <div class="row">
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <svg class="svg-icon text-primary w-2rem h-2rem">
                      <use xlink:href="#destination-map-1"></use>
                    </svg>
                  </div>
                  <h3 class="h5">Soporte Técnico y Beneficios al mantenimiento</h3>
                  <p class="text-muted">Estás acompañado ante preguntas o emergencias. Además aprobecha de descuentos en
                    mantenimiento del coche</p>
                </div>
              </div>
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <div class="icon-rounded bg-primary-light mb-3">
                      <svg class="svg-icon text-primary w-2rem h-2rem">
                        <use xlink:href="#pay-by-card-1"></use>
                      </svg>
                    </div>
                  </div>
                  <h3 class="h5">Seguro de principio a fin</h3>
                  <p class="text-muted">Comprobación de identidad y reputación de los pasajeros da confianza para
                    ofrecer un buen servicio.</p>
                </div>
              </div>
              <div class="col-lg-4 mb-3 mb-lg-0 text-center">
                <div class="px-0 px-lg-3">
                  <div class="icon-rounded bg-primary-light mb-3">
                    <div class="icon-rounded bg-primary-light mb-3">
                      <svg class="svg-icon text-primary w-2rem h-2rem">
                        <use xlink:href="#heart-1"></use>
                      </svg>
                    </div>
                  </div>
                  <h3 class="h5">Eficiencia</h3>
                  <p class="text-muted">Con nuestro algoritmo inteligente obtendrás el máximo beneficio en los
                    traslados.</p>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-primary" @click="$router.push('driverpreview')">Saber más</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <!-- Divider Section-->
  <section class="py-7 position-relative dark-overlay"><img
      src="/frontend/img/photo/photo-1497436072909-60f360e1d4b1.jpg" alt="" class="bg-image">
    <div class="container">
      <div class="overlay-content text-white py-lg-5">
        <h3 class="display-3 font-weight-bold text-serif text-shadow mb-5">Listo para viajar?</h3><button
          class="btn btn-primary" @click="$router.push('login')">Empecemos</button>
      </div>
    </div>
  </section>

  <Footer />
</template>
<script>
import Headers from '../components/Headers.vue'
import Footer from '../components/Footer.vue'
import auth from '@/logic/auth';
import axios from 'axios';

export default {
  components: { Headers, Footer },
    name:'Landing',
    data() {
    return {
      cities: [],
      origin: '',
      destination: '',
      date: '',
      inputType: 'text',
      travels: [],
    };
  },
    created() {
    axios.get('https://api.travicar.com/api/v1/cities')
      .then(response => {
        this.cities = response.data.cities;
      })
      .catch(error => {
        console.error(error);
      });
  },
    computed: {
    userLogged() {
      return auth.getUserLogged();
    },
    PersonType() {
      return auth.getPersonType();
    }
  },

  methods:{
    handleFocus() {
      // Feature detection for input[type=date]
      const input = document.createElement('input');
      input.setAttribute('type', 'date');

      const notADateValue = 'not-a-date';
      input.setAttribute('value', notADateValue);

      // If the browser supports date input, switch type; otherwise, consider a fallback
      if (input.value !== notADateValue) {
        this.inputType = 'date';
      } else {
        // Fallback for browsers that do not support input[type=date]
        // Consider using a date picker library here
      }
    },
    handleBlur() {
      // Switch back to text type on blur if the date is not set
      if (!this.date) {
        this.inputType = 'text';
      }
    },
    search() {
      axios.get('https://api.travicar.com/api/v1/available_travels', 
      { headers: {'Accept': 'application/json'},
        params:{
          origin: this.origin,
          destination: this.destination,
          date: this.date,
        }
      })
        .then(response => {
          console.log(response.data.result) 
          // if travels is empty show alert else go to travels selection
          if (response.data.result.length == 0) {
            this.$swal({
              html: `Mensaje <br> <b> No hay viajes disponibles </b>`,
              icon: 'warning'           
            })
          }
          else
          this.$router.push({ name: 'travelselection', query: {travel_response: JSON.stringify(response.data) }});
        })
        .catch(error => {
          console.error(error);
        });
    },
		ShowAlertok(){
		   this.$swal({
			   html: `Mensaje <br> <b> Sesión Cerrada </b>`,
			   icon: 'success'           
		   }).then(function(){
         auth.deleteUserLogged()
			   window.location.href="/";
		   })
		}
  }

}

</script>

  

<style>
.noborder{
  text-align: left;
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;
}
</style>