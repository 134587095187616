<template>
    <footer class="position-relative z-index-10 d-print-none">
      <!-- Main block - menus, subscribe form-->
      <div class="py-3 bg-gray-200 text-muted"> 
        <div class="container">
          <div class="row">
            <div class="col-lg-4 mb-3 mb-lg-0">
              <div class="font-weight-bold text-uppercase text-dark mb-3">Travicar</div>
              <p>Travicar es una plataforma que conecta a conductores con pasajeros que buscan viajar entre ciudades de forma cómoda, segura y económica.</p>
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#" target="_blank" title="twitter" class="text-muted text-hover-primary"><i class="fab fa-twitter"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="facebook" class="text-muted text-hover-primary"><i class="fab fa-facebook"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="instagram" class="text-muted text-hover-primary"><i class="fab fa-instagram"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="pinterest" class="text-muted text-hover-primary"><i class="fab fa-pinterest"></i></a></li>
                <li class="list-inline-item"><a href="#" target="_blank" title="vimeo" class="text-muted text-hover-primary"><i class="fab fa-vimeo"></i></a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
              <h6 class="text-uppercase text-dark mb-3"></h6>
              <ul class="list-unstyled">
                <li><a href="/" class="text-muted">¿Como usar nuestros servicios?</a></li>
                <li><a href="/" class="text-muted">Servicios a empresas     </a></li>
                <li><a href="/" class="text-muted">Preguntas Frecuentes     </a></li>
                <li><a href="/" class="text-muted">Como ser un Driver en Travicar     </a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
              <h6 class="text-uppercase text-dark mb-3">Paginas</h6>
              <ul class="list-unstyled">
                <li><a href="/" class="text-muted">Política de cookies  <span class="badge badge-info-light ml-1">New</span></a></li>
                <li><a href="/" class="text-muted">Terminos y condiciones  <span class="badge badge-info-light ml-1">New</span></a></li>
                <li><a href="/" class="text-muted">Contacto                                   </a></li>
                <li><a href="/" class="text-muted">Sobre nosotros                                 </a></li>
                <li><a href="/" class="text-muted">Centro de ayuda                                   </a></li>
              </ul>
            </div>
            <div class="col-lg-4">
              <h6 class="text-uppercase text-dark mb-3">Subscribete a Travicar</h6>
              <p class="mb-3"> Para recibir ofertas y novedades en los servicios Subscribete!</p>
              <form action="#" id="newsletter-form">
                <div class="input-group mb-3">
                  <input type="email" placeholder="Your Email Address" aria-label="Your Email Address" class="form-control bg-transparent border-dark border-right-0">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-dark border-left-0"> <i class="fa fa-paper-plane text-lg"></i></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Copyright section of the footer-->
      <div class="py-2 font-weight-light bg-gray-800 text-gray-300">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 text-center text-md-left">
              <p class="text-sm mb-md-0">&copy; 2022 Travi.  Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- /Footer end-->
</template>

<script>
export default {
    name:'Footer'
}
</script>

<style>

</style>