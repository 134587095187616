<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">

      <a class="navbar-brand" href="/">
        <img src="@/assets/img/logo_horizontal.svg" alt="Travi logo">
      </a>

      <div class="mx-auto">
        <a class="nav-link" v-if="personType === 'Driver'" @click="$router.push('/newtravel')">
          <img src="@/assets/img/camino_color.png" class="nav-icon"> Nuevo Viaje
        </a>
        <a class="nav-link" v-if="personType === 'User'" @click="$router.push('/')">
          <img src="@/assets/img/camino_color.png" class="nav-icon"> Nuevo Viaje
        </a>
      </div>


      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">

        <ul class="navbar-nav ml-auto" v-if="userLogged">



          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img :src="'https://dbj9iknualbt6.cloudfront.net/' + personPhoto" alt="Usuario"
                class="avatar avatar-sm avatar-border-white mr-2" title="Usuario">
              <div class=" text-center">
                {{ personName }}
                <br>
                <span class="small text-muted">{{ personType === 'User' ? 'Usuario' : 'Conductor' }}</span>
              </div>
              <i :class="dropdownOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
                @click="dropdownOpen = !dropdownOpen"></i>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" v-if="personType === 'Driver'" @click="$router.push('/perfildriver')">
                <img src="@/assets/img/camino.png" class="align-self-center" style="height: 1.5em;"> Viajes
              </a>
              <a class=" dropdown-item" v-if="personType === 'Driver'" @click="$router.push('/driver/cars')">
                <img src="@/assets/img/coche_bn.png" class="align-self-center" style="height: 1.5em;"> Coches
              </a>
              <a class="dropdown-item" v-if="personType === 'User'" @click="$router.push('/usertravels')">
                <img src="@/assets/img/camino.png" class="align-self-center" style="height: 1.5em;"> Viajes
              </a>
              <a class="dropdown-item" v-if="personType === 'Driver'" @click="$router.push('/driver/info')">
                <img src="@/assets/img/user_bn.png" class="align-self-center" style="height: 1.5em;"> Perfil
              </a>
              <a class="dropdown-item" v-if="personType === 'User'" @click="$router.push('/user/info')">
                <img src="@/assets/img/user_bn.png" class="align-self-center" style="height: 1.5em;"> Perfil
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="userlogout()"><img src="@/assets/img/salida.png"
                  class="align-self-center" style="height: 1em;"> Logout</a>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto" v-else>
          <li class="nav-item"><a class="btn btn-link mb-2" href="/login">Login</a></li>
          <li class="nav-item"><a class="btn btn-link mb-2" href="/register">Regístrate</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import auth from "@/logic/auth";
import { ShowAlert } from "@/logic/aux";

export default {
    name: 'Headers',
    data() {
      return {
      dropdownOpen: false,
    };
  },
    computed: {
    userLogged() {
      return auth.getUserLogged();
    },
    personType() {
      return auth.getPersonType();
    },
    personName() {
      return auth.getPersonName();
    },
    personPhoto() {
      return auth.getPhoto();
    }
  },

  methods:{
    ShowAlert,
    userlogout(){
      auth.logout()
				.then((res) => {
        this.ShowAlert(
          res.data.message,
          res.data.email + ' Gracias por usar Travi. Hasta la próxima',
          res.status,
          '/');
        }).catch((error) => {
          this.ShowAlert(
            'Error',
            error,
            error.status
            );
        });
    }
}
}
</script>

<style>
.spacelist{
    padding-left: 12px;
}
.spacelist2{
    padding-right: 12px;
}
.nav-link.dropdown-toggle::after {
  display: none;
}
.navbar {
  padding: 1rem 0;
}

.navbar-brand img {
  height: 2em;
}

.nav-link {
  display: flex;
  align-items: right;
}

.nav-icon {
  margin-right: 0.5em;
  height: 2em;
}

.mx-auto {
  display: flex;
  align-items: right;
  justify-content: right;
  flex: 1;
}
</style>